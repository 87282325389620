import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import SectionHeading from '../heading';
import PrimaryButton from '../primaryButton';
import { footerVideo } from '../videos';
import { scrollToSection } from '../ScrollToSection';

const JoinWaitlist = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleJoinWaitlistClick = () => {
    // Check if we're on the home page
    if (location.pathname === '/') {
      // If already on home, scroll to the section
      scrollToSection('waitingList');
    } else {
      // If not on home, simply navigate to home
      navigate('/');
    }
  };

  return (
    <section className="section join-waitlist">
      <div className="container footer-video-content">
        <div className="overlay"></div>
        <SectionHeading
          maxWidth={600}
          title="Your TikTok success story starts now!"
          desc="Ready to go viral"
          button={
            <PrimaryButton
              handleClick={handleJoinWaitlistClick}
              primaryBtnText="Join waitlist"
              primaryBtnIcon={
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.3335 8H12.6668"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 3.33333L12.6667 8L8 12.6667"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              }
            />
          }
        />
        <div className="footer-video">
          <video autoPlay muted loop playsInline>
            <source src={footerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};

export default JoinWaitlist;
