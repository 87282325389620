import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import { HeroUIProvider } from '@heroui/system';
import { ToastProvider } from '@heroui/toast';
import { HelmetProvider } from 'react-helmet-async';
// import 'react-notifications/lib/notifications.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HeroUIProvider>
    <ToastProvider placement="top-right" toastOffset={50} />
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  </HeroUIProvider>
);
