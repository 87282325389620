// toastNotification.js
import { addToast } from '@heroui/toast';

// Success toast notification
export const successNotification = (message, title = 'Success') => {
  addToast({
    title: 'Success',
    description: message,
    variant: 'solid',
    color: 'success',
    duration: 5000,
  });
};

// Error toast notification
export const errorNotification = (message, title = 'Error') => {
  addToast({
    title: title,
    description: message,
    variant: 'solid',
    color: 'danger',
    duration: 5000,
  });
};

// Info toast notification (optional)
export const infoNotification = (message, title = 'Information') => {
  addToast({
    title: title,
    description: message,
    variant: 'solid',
    color: 'primary',
    duration: 5000,
  });
};

// Warning toast notification (optional)
export const warningNotification = (message, title = 'Warning') => {
  addToast({
    title: title,
    description: message,
    variant: 'solid',
    color: 'warning',
    duration: 5000,
  });
};
